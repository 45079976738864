import { ModalActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case ModalActions.SHOW_SETTING_ALERT:
      return { ...state, settingAlert: true }

    case ModalActions.HIDE_SETTING_ALERT:
      return { ...state, settingAlert: false }

    case ModalActions.SHOW_ADD_POLICIES:
      return { ...state, showAddPolicy: true }

    case ModalActions.HIDE_ADD_POLICIES:
      return { ...state, showAddPolicy: false }

    case ModalActions.SHOW_CHANGE_POLICIES:
      return { ...state, showChangePolicy: true }

    case ModalActions.HIDE_CHANGE_POLICIES:
      return { ...state, showChangePolicy: false }

    case ModalActions.SHOW_PREVIEW_POLICY:
      return { ...state, showPreviewPolicy: true }

    case ModalActions.HIDE_PREVIEW_POLICY:
      return { ...state, showPreviewPolicy: false }

    default:
      return state
  }
}
