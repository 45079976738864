import React, { useContext, useReducer } from 'react'
import ErrorContext from 'context/tasks/ErrorContext'
import ConfigContext from 'context/config/ConfigContext'
import ErrorReducer from 'context/tasks/ErrorReducer'
import { ErrorActions } from 'context/types'
import { errorReducer } from '../ErrorReducer'

const ErrorProvider = (props) => {
  const initialState = {
    error: null,
    errorFlag: false
  }
  const { t } = useContext(ConfigContext)
  const [state, dispatch] = useReducer(ErrorReducer, initialState)

  const showError = (error) =>
    dispatch({ type: ErrorActions.SHOW_ERROR, payload: errorReducer(error, t) })

  const hideError = () =>
    dispatch({ type: ErrorActions.HIDE_ERROR, payload: null })

  return (
    <ErrorContext.Provider
      value={{
        showError,
        hideError,
        error: state.error,
        errorFlag: state.errorFlag
      }}
    >
      {props.children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
