export enum TaskActions {
  GET_ALL_POLICIES,
  GET_ALL_ASSIGNMENTS,
  GET_SELECTED_ASSIGNMENT_POLICIES,
  CLONE_SELECTED_ASSIGNMENT_POLICIES,
  CLONE_SAVED_ASSIGNMENT_POLICIES,
  GET_COMMON_POLICIES,
  SHOW_COMMON_POLICIES
}

export enum ModalActions {
  HIDE_ADD_POLICIES,
  SHOW_ADD_POLICIES,
  HIDE_CHANGE_POLICIES,
  SHOW_CHANGE_POLICIES,
  HIDE_SETTING_ALERT,
  SHOW_SETTING_ALERT,
  SHOW_PREVIEW_POLICY,
  HIDE_PREVIEW_POLICY
}

export enum CollectionsActions {
  GET_ALL_COLLECTIONS
}

export enum ErrorActions {
  SHOW_ERROR,
  HIDE_ERROR
}
