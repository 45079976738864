import { TableI18n } from '@veneer/core'
import { useContext, useMemo } from 'react'
import ConfigContext from 'context/config/ConfigContext'

const Retrievei18nItems = (): TableI18n => {
  const { t } = useContext(ConfigContext)

  const retrieveValue = useMemo(() => {
    return {
      actionButton: t('common.table.action-button'),
      cancelButton: t('common.table.cancel-button'),
      clearAllFilters: t('common.table.clear-filters'),
      columnOptions: t('common.table.column-options'),
      columnResizeTooltip: t('common.table.resize-tool-tip'),
      deselectAllItems: t('common.table.deselect-all'),
      downButton: t('common.table.down-button'),
      itemSelected: t('common.table.item-selected'),
      itemsSelected: t('common.table.items-selected'),
      noItems: t('common.table.no-items'),
      resetToDefault: t('common.table.reset-default'),
      saveButton: t('common.table.save-button'),
      selectAllItems: t('common.table.select-items'),
      selectAllPageItems: t('common.table.select-page-items'),
      sortByAscending: t('common.table.sort-by-ascending'),
      sortByAscendingActive: t('common.table.sort-by-ascending-active'),
      sortedAscending: t('common.table.sorted-ascending'),
      sortByDescending: t('common.table.sort-by-descending'),
      sortByDescendingActive: t('common.table.sort-by-descending-active'),
      sortedDescending: t('common.table.sorted-descending'),
      upButton: t('common.table.up-button')
    }
  }, [])
  return retrieveValue
}

export default Retrievei18nItems
