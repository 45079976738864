import React, { useContext } from 'react'
import { Modal } from '@veneer/core'
import ModalContext from 'context/modal/ModalContext'
import {
  POLICIES_MFE,
  POLICIES_MFE_TYPE
} from 'components/tasks/constants/constant'
import { MicroFrontend } from 'components/MicroFrontend'

export const Preview = (props) => {
  const modalContext = useContext(ModalContext)
  const { showPreview, hidePreview } = modalContext
  const { previewPolicy, setPolicyForPreview } = props
  const onCancelClick = () => {
    hidePreview()
    setPolicyForPreview(null)
  }
  return (
    <div className={'preview-policy-wrapper'}>
      <Modal
        id={'preview-policy-modal'}
        closeButton
        onClose={() => onCancelClick()}
        show={showPreview}
        title={previewPolicy?.policyName}
        className={'preview-policy-modal'}
      >
        <MicroFrontend
          disableContainerPadding
          {...props}
          type={POLICIES_MFE_TYPE}
          component={POLICIES_MFE}
          policyId={previewPolicy?.policyId}
        />
      </Modal>
    </div>
  )
}

export default Preview
