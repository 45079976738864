import React, { useReducer } from 'react'
import ModalContext from 'context/modal/ModalContext'
import ModalReducer from 'context/modal/ModalReducer'
import { ModalActions } from 'context/types'

const ModalProvider = (props) => {
  const initialState = {
    showAddPolicy: false,
    showChangePolicy: false,
    settingAlert: false,
    showPreviewPolicy: false
  }
  const [state, dispatch] = useReducer(ModalReducer, initialState)

  const hideAddPolicies = () => {
    dispatch({
      type: ModalActions.HIDE_ADD_POLICIES
    })
  }

  const showAddPolicies = () => {
    dispatch({
      type: ModalActions.SHOW_ADD_POLICIES
    })
  }

  const hideChangePolicies = () => {
    dispatch({
      type: ModalActions.HIDE_CHANGE_POLICIES
    })
  }

  const showChangePolicies = () => {
    dispatch({
      type: ModalActions.SHOW_CHANGE_POLICIES
    })
  }

  const hideSettingAlert = () => {
    dispatch({
      type: ModalActions.HIDE_SETTING_ALERT
    })
  }

  const showSettingAlert = () => {
    dispatch({
      type: ModalActions.SHOW_SETTING_ALERT
    })
  }

  const showPreview = () => {
    dispatch({
      type: ModalActions.SHOW_PREVIEW_POLICY
    })
  }

  const hidePreview = () => {
    dispatch({
      type: ModalActions.HIDE_PREVIEW_POLICY
    })
  }

  return (
    <ModalContext.Provider
      value={{
        showAddPolicy: state.showAddPolicy,
        hideAddPolicies,
        showAddPolicies,
        settingAlert: state.settingAlert,
        hideSettingAlert,
        showSettingAlert,
        showChangePolicy: state.showChangePolicy,
        hideChangePolicies,
        showChangePolicies,
        showPreviewPolicy: state.showPreviewPolicy,
        showPreview,
        hidePreview
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
