import React, { useReducer, useContext } from 'react'
import TasksContext from 'context/tasks/TasksContext'
import TasksReducer from 'context/tasks/TasksReducer'
import { TaskActions } from 'context/types'
import { FleetMgtSvcClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import ErrorContext from 'context/tasks/ErrorContext'
import ConfigContext from 'context/config/ConfigContext'
import { errorReducer } from 'context/ErrorReducer'

const offset = 0
const limit = -1

const TasksProvider = (props) => {
  const errorContext = useContext(ErrorContext)
  const { showError } = errorContext
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  const initialState = {
    policies: null,
    assignments: null,
    selectedAssignmentPolicies: null,
    clonedAssignmentPolicies: [],
    clonedSavedAssignmentPolicies: [],
    showCommonPolicies: false,
    commonPolicies: []
  }
  const [state, dispatch] = useReducer(TasksReducer, initialState)
  const stackFromShell = props.stack ? props.stack : Stack.dev

  const fleetMgtSvcClient = new FleetMgtSvcClient(
    stackFromShell,
    props.authProvider
  )

  const getAllPolicies = async (displayToaster) => {
    try {
      displayPolicies(null)
      const res = await fleetMgtSvcClient.getAllPolicies(offset, limit)
      displayPolicies(res.data)
    } catch (error) {
      displayPolicies({ items: [] })
      const newError = errorReducer(error, t).error
      displayToaster(
        'getAllPolicies-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const getAllAssignments = async (displayToaster) => {
    try {
      displayAssignments(null)
      const res = await fleetMgtSvcClient.getAllAssignments(offset, limit)
      displayAssignments(res.data.items)
    } catch (error) {
      displayAssignments([])
      const newError = errorReducer(error, t).error
      displayToaster(
        'getAllAssignments-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const getAssignmentsPolicies = (collectionId, parentId) => {
    if (!state.assignments || !collectionId) {
      displayAssignmentPolicies(null)
      cloneSavedPolicies(null)
      displayCommonPolicies(null)
      showCommonPolicies(false)
      return
    }
    const assignment = state.assignments.find(
      (assign) => assign.groupId === collectionId
    )
    displayAssignmentPolicies(assignment?.policies || [])
    cloneSavedPolicies(assignment?.policies || [])
    if (collectionId !== parentId) {
      const parentAssignment = state.assignments.find(
        (assign) => assign.groupId === parentId
      )
      displayCommonPolicies(parentAssignment?.policies || [])
      showCommonPolicies(true)
    } else {
      displayCommonPolicies([])
      showCommonPolicies(false)
    }
  }

  const displayCommonPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_COMMON_POLICIES, payload })
  }

  const showCommonPolicies = (payload) => {
    dispatch({ type: TaskActions.SHOW_COMMON_POLICIES, payload })
  }

  const cloneSavedPolicies = (payload) => {
    dispatch({ type: TaskActions.CLONE_SAVED_ASSIGNMENT_POLICIES, payload })
  }

  const updateAssignmentPolicies = (updatedPolicies) => {
    try {
      displayAssignmentPolicies(updatedPolicies || [])
    } catch (error) {
      showError(error)
    }
  }

  const cloneAssignmentPolicies = (policies) => {
    try {
      clonePolicies(policies || [])
    } catch (error) {
      showError(error)
    }
  }

  const clonePolicies = (payload) => {
    dispatch({ type: TaskActions.CLONE_SELECTED_ASSIGNMENT_POLICIES, payload })
  }

  const displayAssignments = (payload) => {
    dispatch({ type: TaskActions.GET_ALL_ASSIGNMENTS, payload })
  }

  const displayAssignmentPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_SELECTED_ASSIGNMENT_POLICIES, payload })
  }

  const createAssignment = async (assignment, displayToast) => {
    try {
      await fleetMgtSvcClient.createAssignment(assignment)
      if (displayToast) {
        displayToast()
      }
    } catch (error) {
      showError(error)
    }
  }

  const updateAssignment = async (assignment, id, displayToast) => {
    try {
      await fleetMgtSvcClient.updateAssignment({ ...assignment, id })
      if (displayToast) {
        displayToast()
      }
    } catch (error) {
      showError(error)
    }
  }

  const deleteAssignment = async (id, displayToast) => {
    try {
      await fleetMgtSvcClient.deleteAssignment(id)
      if (displayToast) {
        displayToast()
      }
    } catch (error) {
      showError(error)
    }
  }

  const displayPolicies = (payload) => {
    dispatch({ type: TaskActions.GET_ALL_POLICIES, payload })
  }

  return (
    <TasksContext.Provider
      value={{
        policies: state.policies,
        assignments: state.assignments,
        selectedAssignmentPolicies: state.selectedAssignmentPolicies,
        clonedAssignmentPolicies: state.clonedAssignmentPolicies,
        clonedSavedAssignmentPolicies: state.clonedSavedAssignmentPolicies,
        showCommonPolicies: state.showCommonPolicies,
        commonPolicies: state.commonPolicies,
        getAllPolicies,
        getAllAssignments,
        getAssignmentsPolicies,
        updateAssignmentPolicies,
        cloneAssignmentPolicies,
        createAssignment,
        updateAssignment,
        deleteAssignment
      }}
    >
      {props.children}
    </TasksContext.Provider>
  )
}

export default TasksProvider
