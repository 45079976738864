import React, { useEffect, useMemo, useContext } from 'react'
import { Table } from '@veneer/core'
import ConfigContext from 'context/config/ConfigContext'
import TasksContext from 'context/tasks/TasksContext'
import './index.scss'
import { TaskType } from 'utils/taskHelper'
import { getCategories, getGroupName } from 'utils/utilities'
import Retrievei18nItems from 'utils/Retrievei18nItems'

export const CommonAssignmentPolicies = (props) => {
  const cContext = useContext(ConfigContext)
  const taskContext = useContext(TasksContext)
  const { commonPolicies } = taskContext
  const { t } = cContext
  const { currentCollection, handleLinkClick, addNoPolicyMessage } = props

  const commonPolicesData = useMemo(() => {
    const getTaskType = (taskType) => {
      switch (taskType) {
        case TaskType.AssessRemediate:
          return t('task.assessRemediateOptions.assessRemediate')
        case TaskType.Assess:
          return t('task.assessRemediateOptions.assessOnly')
        case TaskType.Remediate:
          return t('task.assessRemediateOptions.remediateOnly')
        default:
          return ''
      }
    }

    return commonPolicies
      ? commonPolicies.map((policy) => {
          return {
            id: policy.policyId,
            policyName: (
              <a
                role="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleLinkClick(e, policy.policyId, policy.policyName)
                }}
                id={policy.policyId}
              >
                {policy.policyName}
              </a>
            ),
            category: getCategories(policy.policyAttributes, t),
            assignedTo: t('task.group.all'),
            assessRemediate: getTaskType(policy.assignmentType)
          }
        })
      : null
  }, [commonPolicies, handleLinkClick, t])

  const params = useMemo(() => {
    return {
      numberOfPolicies: commonPolicesData?.length,
      group: getGroupName(currentCollection, t)
    }
  }, [commonPolicesData, currentCollection, t])

  useEffect(() => {
    if (commonPolicies?.length === 0) {
      addNoPolicyMessage(
        'commonPolicies',
        t('task.table.noItemsCommonGroupDescription')
      )
    }
  }, [commonPolicies, addNoPolicyMessage, t])

  return (
    <div className="common-assignment-policies-wrapper">
      <p className="common-assignment-policies-description">
        {commonPolicesData?.length > 0
          ? t('task.commonPolicies.pageDescriptionMessage', params)
          : t(
              'task.commonPolicies.pageDescriptionMessageEmptyAssignment',
              params
            )}
      </p>
      <div id="commonPolicies">
        <Table
          columns={[
            {
              id: 'id',
              label: 'id',
              index: 'hidden'
            },
            {
              id: 'policyName',
              label: t('task.columnHeader.policyName')
            },
            {
              id: 'category',
              label: t('task.columnHeader.category')
            },
            {
              id: 'assignedTo',
              label: t('task.columnHeader.assignedTo')
            },
            {
              id: 'assessRemediate',
              label: t('task.columnHeader.assessRemediate')
            }
          ]}
          data={commonPolicesData || []}
          loading={commonPolicesData === null}
          preferences={{
            width: [
              { columnId: 'policyName', width: 25 },
              { columnId: 'category', width: 25 },
              { columnId: 'assignedTo', width: 25 },
              { columnId: 'assessRemediate', width: 25 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
      </div>
    </div>
  )
}

export default CommonAssignmentPolicies
