import { ErrorActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case ErrorActions.SHOW_ERROR:
      return { ...state, ...action.payload, errorFlag: true }

    case ErrorActions.HIDE_ERROR:
      return { ...state, error: null, errorFlag: false }

    default:
      return state
  }
}
