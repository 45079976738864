import { CollectionsActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case CollectionsActions.GET_ALL_COLLECTIONS:
      return {
        ...state,
        collections: action.payload
      }

    default:
      return state
  }
}
