import React, { useState } from 'react'
import { SideBar } from '@veneer/core'

const GroupsPanel = (props) => {
  const [showSideBar, setShowSideBar] = useState(false)

  return props.isWex ? (
    <SideBar
      behavior={'persistent'}
      position={'start'}
      collapsable
      show={showSideBar}
      content={props.children}
      onClose={() => setShowSideBar(false)}
      onCollapse={() => setShowSideBar(false)}
      onExpand={() => setShowSideBar(true)}
      size={256} //task-group-column
    />
  ) : (
    props.children
  )
}

export default GroupsPanel
