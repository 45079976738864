import React, { useReducer, useContext } from 'react'
import CollectionsContext from 'context/tasks/CollectionsContext'
import CollectionsReducer from 'context/tasks/CollectionsReducer'
import { CollectionsActions } from 'context/types'
import { CollectionClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import ConfigContext from 'context/config/ConfigContext'
import { errorReducer } from 'context/ErrorReducer'

const CollectionsProvider = (props) => {
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  const initialState = {
    collections: null
  }
  const [state, dispatch] = useReducer(CollectionsReducer, initialState)
  const stackFromShell = props.stack ? props.stack : Stack.dev

  const collectionClient = new CollectionClient(
    stackFromShell,
    props.authProvider
  )

  const displayCollections = (payload) => {
    dispatch({ type: CollectionsActions.GET_ALL_COLLECTIONS, payload })
  }

  const getAllCollection = async (displayToaster) => {
    try {
      displayCollections(null)
      const res = await collectionClient.getCollections(0, -1)
      displayCollections(res.data)
    } catch (error) {
      const newError = errorReducer(error, t).error
      displayCollections({ contents: [] })
      displayToaster(
        'getAllCollection-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  return (
    <CollectionsContext.Provider
      value={{
        collections: state.collections,
        getAllCollection
      }}
    >
      {props.children}
    </CollectionsContext.Provider>
  )
}

export default CollectionsProvider
