import React, { useContext, useEffect } from 'react'
import {
  IconArrowUp,
  IconArrowDown,
  Button,
  Modal,
  ButtonGroup,
  Card,
  Checkbox,
  Scrollbar
} from '@veneer/core'
import ConfigContext from 'context/config/ConfigContext'
import TasksContext from 'context/tasks/TasksContext'
import ModalContext from 'context/modal/ModalContext'
import _ from 'lodash'
import { FlexRow, FlexColumn } from 'styles/styles'

export const ChangePolicyPriority = (props) => {
  const cContext = useContext(ConfigContext)
  const taskContext = useContext(TasksContext)
  const modalContext = useContext(ModalContext)
  const {
    selectedAssignmentPolicies,
    clonedAssignmentPolicies,
    cloneAssignmentPolicies,
    updateAssignmentPolicies
  } = taskContext
  const { saveChangePolicyPriority, tableList } = props
  const [changePolicyData, setChangePolicyData] = React.useState([])
  const [selectedChangePolicy, setSelectedChangePolicy] = React.useState([])
  const [anyChanges, setAnyChanges] = React.useState(true)
  const { t } = cContext
  const { showChangePolicy, hideChangePolicies } = modalContext

  useEffect(() => {
    if (
      clonedAssignmentPolicies &&
      clonedAssignmentPolicies.length > 0 &&
      changePolicyData.length === 0
    ) {
      setChangePolicyData(selectedAssignmentPolicies)
    }
  }, [clonedAssignmentPolicies, changePolicyData])

  useEffect(() => {
    setChangePolicyData(selectedAssignmentPolicies)
  }, [tableList])

  useEffect(() => {
    if (clonedAssignmentPolicies?.length > 0 && changePolicyData?.length) {
      if (_.isEqual(clonedAssignmentPolicies, changePolicyData)) {
        setAnyChanges(true)
      } else {
        setAnyChanges(false)
      }
    }
  }, [changePolicyData, clonedAssignmentPolicies])

  const handleChangePolicies = (event, data) => {
    if (event.target.checked) {
      setSelectedChangePolicy([...selectedChangePolicy, data])
    } else {
      const updatedChangePolicies = selectedChangePolicy.filter(
        (scp) => scp.policyId !== data.policyId
      )
      setSelectedChangePolicy(updatedChangePolicies)
    }
  }

  const swapArrayItems = (a, i, j) => {
    const temp = a[i]
    a[i] = a[j]
    a[j] = temp
  }

  const isSelected = (policyId) =>
    selectedChangePolicy.some((policy) => policy.policyId === policyId)

  const onMoveUp = (simulate = false) => {
    let moved = 0
    for (let i = 1; i < changePolicyData?.length; i++) {
      if (
        isSelected(changePolicyData[i].policyId) &&
        !isSelected(changePolicyData[i - 1].policyId)
      ) {
        if (simulate) {
          return true
        }
        swapArrayItems(changePolicyData, i - 1, i)
        moved++
      }
    }
    if (moved) {
      setChangePolicyData([...changePolicyData])
    }
    return moved > 0
  }

  const onMoveDown = (simulate = false) => {
    let moved = 0
    for (let i = changePolicyData?.length - 2; i >= 0; i--) {
      if (
        isSelected(changePolicyData[i].policyId) &&
        !isSelected(changePolicyData[i + 1].policyId)
      ) {
        if (simulate) {
          return true
        }
        swapArrayItems(changePolicyData, i, i + 1)
        moved++
      }
    }
    if (moved) {
      setChangePolicyData([...changePolicyData])
    }
    return moved > 0
  }

  const onResetClick = () => {
    setChangePolicyData([...clonedAssignmentPolicies])
  }

  const onCancelClick = () => {
    setAnyChanges(true)
    setSelectedChangePolicy([])
    updateAssignmentPolicies(clonedAssignmentPolicies)
    setChangePolicyData([])
    cloneAssignmentPolicies([])
    hideChangePolicies()
  }

  const onSaveClick = () => {
    setAnyChanges(true)
    setSelectedChangePolicy([])
    setChangePolicyData([])
    cloneAssignmentPolicies([])
    saveChangePolicyPriority(changePolicyData)
    hideChangePolicies()
  }

  return (
    <Modal
      id={'change-policy-priority-modal'}
      closeOnBlur={false}
      onClose={() => onCancelClick()}
      show={showChangePolicy}
      title={t('task.changePolicyPriority.title')}
      className={'change-policy-priority-modal'}
      footer={
        <ButtonGroup>
          <Button
            id={'saveButton'}
            disabled={changePolicyData?.length > 1 ? anyChanges : true}
            onClick={() => onSaveClick()}
          >
            {t('common.save')}
          </Button>
          <Button
            id={'cancelButton'}
            appearance={'secondary'}
            onClick={() => onCancelClick()}
          >
            {t('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'change-policy-priority-modal-description'}>
        {t('task.changePolicyPriority.itemPriority')}
      </p>

      <FlexRow>
        <Card
          className={'change-policy-priority-elements'}
          border={'outlined'}
          content={
            <Scrollbar customStyle={{ height: '220px' }}>
              <FlexColumn
                className={'change-policy-priority-element-box'}
                id={'changePolicy'}
              >
                {changePolicyData &&
                  changePolicyData.map((pd, i) => (
                    <FlexRow
                      className={'change-policy-priority-element'}
                      key={i}
                    >
                      <Checkbox
                        id={'checkbox-unchecked'}
                        onChange={(event) => handleChangePolicies(event, pd)}
                        checked={
                          selectedChangePolicy.filter(
                            (policy) => policy.policyId === pd.policyId
                          ).length > 0
                        }
                      />
                      <div
                        className={
                          'change-policy-priority-element-text marginLeft8'
                        }
                      >
                        {pd.policyName}
                      </div>
                    </FlexRow>
                  ))}
              </FlexColumn>
            </Scrollbar>
          }
        />
        <FlexColumn>
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowUp />}
            onClick={() => onMoveUp()}
            disabled={!onMoveUp(true)}
          />
          <Button
            appearance={'ghost'}
            leadingIcon={<IconArrowDown />}
            onClick={() => onMoveDown()}
            disabled={!onMoveDown(true)}
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow className={'marginTop12'}>
        <Button
          id={'resetButton'}
          appearance={'ghost'}
          disabled={changePolicyData?.length > 1 ? anyChanges : true}
          onClick={() => onResetClick()}
        >
          {t('common.reset')}
        </Button>
      </FlexRow>
    </Modal>
  )
}

export default ChangePolicyPriority
