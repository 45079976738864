import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TasksTable from 'components/tasks/taskstable'
import Error from '../modal/error'

const RouteTasksTab = (props) => {
  const { navigation, useToast } = props
  const baseurl =
    props.customRelativePath !== undefined
      ? props.customRelativePath
      : '/policies/assignments'
  const baseName = navigation
    ? navigation.createHref({ pathname: baseurl })
    : baseurl

  const { addToast, toasts, updateToast } = useToast()
  const displayToaster = (toasterId, msg, msgType = 'positive') => {
    if (
      toasts &&
      toasts.length > 0 &&
      toasts.findIndex((item) => item.id === toasterId) >= 0
    ) {
      updateToast(toasterId, {
        id: toasterId,
        text: msg,
        type: msgType
      })
    } else {
      addToast({
        id: toasterId,
        type: msgType,
        text: msg
      })
    }
  }

  const taskProps = { ...props, baseurl, displayToaster }

  return (
    <Router basename={baseName}>
      <Switch>
        <Route exact path={'/'}>
          <TasksTable {...taskProps} />
        </Route>
      </Switch>
      <Error />
    </Router>
  )
}

export default RouteTasksTab
