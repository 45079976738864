import PoliciesCategories from './PoliciesCategories'
import { Group } from 'utils/Group'

/**
 * getCategories
 *
 * @param attributes policy attributes
 * @param t localization
 * @returns {string} category
 */
export function getCategories(attributes: { name: string }[], t): string {
  const categories = PoliciesCategories.getPolicyCategories([], attributes)
  const category = (key) => t(`task.commonPolicies.categories.${key}`)
  return categories.length > 1
    ? t('task.commonPolicies.more', {
        name: category(categories[0]),
        count: categories.length - 1
      })
    : categories.length
    ? category(categories[0])
    : ''
}

/**
 * Get group name, localized if necessary
 *
 * @param group device group
 * @param t localization
 * @returns {string} group name: localized or user-defined
 */

export function getGroupName(group: Group, t): string {
  return ['All', 'Ungrouped'].includes(group?.name) && group?.builtIn == true
    ? t(`task.group.${group?.name.toLowerCase()}`)
    : group?.name
}

/**
 * Find Predefined group
 *
 * @param groupArray array of Groups
 * @param groupName groupName
 * @returns {Group} group
 */
export function findPredefinedGroup(
  groupArray: Array<Group>,
  groupName: string
) {
  return groupArray?.find(
    (obj) => obj.name === groupName && obj.builtIn == true
  )
}
