import React, { useContext, useEffect, useMemo } from 'react'
import {
  ButtonGroup,
  Button,
  Modal,
  Table,
  Search,
  Scrollbar
} from '@veneer/core'
import { TableSortBy, SortTypes } from '@veneer/core'
import ConfigContext from 'context/config/ConfigContext'
import ModalContext from 'context/modal/ModalContext'
import TasksContext from 'context/tasks/TasksContext'
import ErrorContext from 'context/tasks/ErrorContext'
import { getCategories } from 'utils/utilities'
import { ASCENDING, DESCENDING } from 'components/tasks/constants/constant'
import { getDecoratedName } from 'utils/decoratedName'
import 'styles/global.scss'
import Retrievei18nItems from 'utils/Retrievei18nItems'

const pageSize = 8
const defSortOrder: SortTypes = ASCENDING
const defSorting: TableSortBy = {
  id: 'policyName',
  type: defSortOrder
}

export const AddPolicy = (props) => {
  const cContext = useContext(ConfigContext)
  const taskContext = useContext(TasksContext)
  const modalContext = useContext(ModalContext)
  const errorContext = useContext(ErrorContext)
  const { selectedPolicies, addPolicyToTable } = props
  const { t } = cContext
  const { hideAddPolicies, showAddPolicy } = modalContext
  const { tableList, displayToaster } = props
  const { getAllPolicies, policies } = taskContext
  const { error } = errorContext
  const [addPolicyTableData, setAddPolicyTableData] = React.useState(null)
  const [searchedAddPolicyTableData, setSearchedAddPolicyTableData] =
    React.useState(null)
  const [searchValue, setSearchValue] = React.useState('')
  const [isTableDataSet, setIsTableData] = React.useState(false)
  const [sorting, setSorting] = React.useState(defSorting)

  function descendingComparator(a, b, orderBy) {
    const aSort = a[orderBy] && a[orderBy].toString().toUpperCase()
    const bSort = b[orderBy] && b[orderBy].toString().toUpperCase()

    if (bSort < aSort) {
      return -1
    }
    if (bSort > aSort) {
      return 1
    }
    return 0
  }

  function sort(array, orderBy, orderType) {
    return array?.sort((a, b) => {
      return orderType === DESCENDING
        ? descendingComparator(a, b, orderBy)
        : -descendingComparator(a, b, orderBy)
    })
  }

  const sortedData = sort(
    searchedAddPolicyTableData
      ? searchedAddPolicyTableData
      : addPolicyTableData,
    sorting.id,
    sorting.type
  )

  const checkExist = (policyId) => {
    const isPolicyPresent = tableList?.filter(
      (policy) => policy.id === policyId
    )
    return !!isPolicyPresent?.length
  }

  const checkPolicyChecked = (policyId) => {
    const isPolicyChecked = addPolicyTableData?.find(
      (policy) => policy.id === policyId
    )
    return isPolicyChecked?.rowConfig?.selected
  }

  const getDataForTable = (data) => {
    return data && data.items
      ? data.items.map((policy) => {
          const decoratedName = getDecoratedName(
            policy.name,
            searchValue?.toLowerCase(),
            'policySearchToken'
          )
          return {
            id: policy.id,
            rowConfig: {
              policyName: policy.name,
              selected: isTableDataSet
                ? checkPolicyChecked(policy.id)
                : checkExist(policy.id)
            },
            policyName: decoratedName.length ? decoratedName : policy.name,
            category: getCategories(policy.attributes, t),
            policyAttributes: policy.attributes
          }
        })
      : null
  }
  async function setData() {
    const tData = getDataForTable(policies)
    setAddPolicyTableData(tData)
  }

  useEffect(() => {
    if (showAddPolicy) {
      setAddPolicyTableData(null)
      getAllPolicies(displayToaster)
      setSearchValue('')
      setIsTableData(false)
      setSearchedAddPolicyTableData(null)
    }
  }, [showAddPolicy])

  useEffect(() => {
    setData()
  }, [policies, tableList])

  const disableAdd = useMemo(() => {
    if (!(addPolicyTableData && tableList)) {
      return true
    }
    const selected = addPolicyTableData.filter((x) => x.rowConfig.selected)
    if (selected.length !== tableList.length) {
      return false
    }
    return selected.every((x) => tableList.find((y) => y.id === x.id))
  }, [addPolicyTableData, tableList])

  useEffect(() => {
    if (!isTableDataSet && addPolicyTableData) {
      setIsTableData(true)
    }
  }, [isTableDataSet, addPolicyTableData])

  useEffect(() => {
    if (searchValue.length) {
      const data = getDataForTable(policies)
      const newData = data?.filter((item) => {
        if (item.policyName && item.policyName.length > 1) {
          return item
        }
      })
      setSearchedAddPolicyTableData(newData)
    } else {
      setSearchedAddPolicyTableData(null)
    }
  }, [searchValue])

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const newTableData = [...addPolicyTableData]

    const rowIndex = newTableData.findIndex((row) => row.id === rowId)
    newTableData[rowIndex].rowConfig.selected = checked
    setAddPolicyTableData(newTableData)
    if (searchedAddPolicyTableData) {
      const newSearchedTableData = [...searchedAddPolicyTableData]
      const rowIndex = newSearchedTableData.findIndex((row) => row.id === rowId)
      newSearchedTableData[rowIndex].rowConfig.selected = checked
      setSearchedAddPolicyTableData(newSearchedTableData)
    }
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    if (searchedAddPolicyTableData) {
      const pagedDataUids = searchedAddPolicyTableData.map(({ id }) => id)
      const newTableData = searchedAddPolicyTableData.map((row) => {
        if (pagedDataUids.indexOf(row.id) !== -1) {
          const rowCopy = row
          row.rowConfig.selected = checked
          return rowCopy
        }
        return row
      })
      setSearchedAddPolicyTableData(newTableData)
    }
    const pagedDataUids = searchedAddPolicyTableData
      ? searchedAddPolicyTableData.map(({ id }) => id)
      : addPolicyTableData?.map(({ id }) => id)
    const newTableData = addPolicyTableData.map((row) => {
      if (pagedDataUids.indexOf(row.id) !== -1) {
        const rowCopy = row
        row.rowConfig.selected = checked
        return rowCopy
      }
      return row
    })
    setAddPolicyTableData(newTableData)
  }

  const getPageSelectionState = React.useCallback(() => {
    const total = addPolicyTableData?.length
    const selected = addPolicyTableData
      ? addPolicyTableData.filter((row) => row?.rowConfig?.selected).length
      : 0

    switch (selected) {
      case 0:
        return 'none'
      case total:
        return 'all'
      default:
        return 'indeterminated'
    }
  }, [addPolicyTableData])

  const onAddClick = () => {
    const data = getDataForTable(policies)
    const selectedPolicies = data?.filter(
      (row) => row.rowConfig.selected === true
    )
    addPolicyToTable(
      selectedPolicies.map((x) => {
        const {
          id,
          rowConfig: { policyName },
          policyAttributes
        } = x
        return { id, policyName, policyAttributes }
      })
    )
  }

  const onCancelClick = () => {
    hideAddPolicies()
  }
  return (
    <div className={'add-policy-wrapper'}>
      <Modal
        id={'add-policy-modal'}
        closeOnBlur={false}
        onClose={() => hideAddPolicies()}
        show={showAddPolicy}
        title={t('task.addPolicy.title')}
        className={'add-policy-modal'}
        footer={
          <ButtonGroup>
            <Button
              id={'addButton'}
              onClick={() => onAddClick()}
              disabled={
                selectedPolicies === 0 ||
                (policies?.items?.length === 0 && error) ||
                disableAdd
              }
            >
              {t('common.add')}
            </Button>
            <Button
              id={'cancelButton'}
              appearance={'secondary'}
              onClick={() => onCancelClick()}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <p className={'add-policy-modal-description'}>
          {t('task.addPolicy.description')}
        </p>
        <Search
          id={'policy-search'}
          className={'policyAddSearch'}
          placeholder={t('task.addPolicy.search')}
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
        />
        <Scrollbar customStyle={{ height: '464px' }}>
          <Table
            columns={[
              {
                id: 'id',
                label: 'id',
                index: 'hidden'
              },
              {
                id: 'policyName',
                label: t('task.columnHeader.policyName')
              },
              {
                id: 'category',
                label: t('task.columnHeader.category'),
                sortable: false
              }
            ]}
            data={sortedData || []}
            onSort={(_, sortBy) => setSorting(sortBy)}
            loading={addPolicyTableData === null}
            loadingDataLength={pageSize}
            onSelect={handleRowSelect}
            onSelectAllPageItems={handleSelectAllPageItems}
            preferences={{
              width: [
                { columnId: 'policyName', width: 50 },
                { columnId: 'category', width: 50 }
              ],
              sortBy: sorting
            }}
            rowSelector={'multiSelection'}
            rowSelectAllState={getPageSelectionState()}
            className={
              addPolicyTableData && addPolicyTableData?.length > 0
                ? 'loaded-table'
                : 'no-data'
            }
            i18n={Retrievei18nItems()}
          />
        </Scrollbar>
      </Modal>
    </div>
  )
}

export default AddPolicy
