export const errorReducer = (payload, t) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let msgText = t('errors.code.other') // default
  let msgCode = null

  if (payload && payload.response) {
    const { response } = payload
    msgCode = response.status // default
    if (response.statusText) {
      msgCode += ' - ' + response.statusText // with text
    }

    const { data } = response
    if (data) {
      const assetkey = 'errors.code.' + data.code
      if (t(assetkey).indexOf(assetkey) == -1) {
        msgText = t(assetkey) // message by known error code
        msgCode = null
      } else {
        if (data.message) {
          msgText = data.message // unknown message
        }
        if (data.code) {
          msgCode += ', ' + data.code // unknown code
        }
      }
    }
  }

  return {
    error: { errorMessage: msgCode ? `${msgText} (${msgCode})` : msgText }
  }
}
