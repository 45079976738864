import React, { useContext } from 'react'
import { Button } from '@veneer/core'
import './index.scss'
import 'styles/global.scss'
import ConfigContext from 'context/config/ConfigContext'

const TableFooter = (props) => {
  const { disabledAction } = props
  const { t } = useContext(ConfigContext)
  return (
    <div className={'task-fixed'}>
      <div className={'task-buttons-fixed'}>
        <Button
          id={'cancelButton'}
          appearance={'secondary'}
          onClick={props.onCancelClick}
        >
          {t('common.cancel')}
        </Button>
        <div className={'marginLeft12'}>
          <Button
            id={'actionButton'}
            onClick={props.action.onClick}
            disabled={disabledAction}
          >
            {t(`task.footer.${props.action.name}`)}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TableFooter
